<template>
  <div style="width: 100%">
    <vs-table max-items="10" pagination :data="masterMerge">
      <template slot="thead" class="exc">
        <vs-th>Date</vs-th>
        <vs-th>Total Calls|Total Unique Calls|Duration|IN|OUT|MIS</vs-th>
        <vs-th>Connected Calls</vs-th>
        <vs-th>Long Duration Calls 30-60 Mins | > 60 Mins</vs-th>
        <vs-th>Total NUC | Duration</vs-th>
        <vs-th>Attendance</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="index" v-for="(item, index) in data">
          <vs-td :data="item.spoc_name">
            <div>{{ item.date }}</div>
          </vs-td>

          <vs-td :data="item.total_calls">
            <div>
              {{ item.total_calls }} | {{ item.total_unique_calls }} |
              {{ item.total_duration }} | {{ item.incoming_call_count }} |
              {{ item.outgoing_call_count }} | {{ item.missed_call_count }}
            </div>
          </vs-td>

          <vs-td :data="item.connected_calls">
            <div>
              <a
                href="#"
                @click="
                  EmitEvent('utilization-mwb', [
                    item.connected_calls_arr,
                    item.spoc_name,
                    item.connected_calls,
                    'Connected Calls',
                  ])
                "
                >{{ item.connected_calls }}</a
              >
            </div>
          </vs-td>

          <vs-td :data="item.call_30_60">
            <div>
              <a
                href="#"
                @click="
                  EmitEvent('utilization-mwb', [
                    item.call_30_60_arr,
                    item.spoc_name,
                    item.call_30_60,
                    'Long Calls - 30-60 mins',
                  ])
                "
                >{{ item.call_30_60 }}</a
              >
              |
              <a
                href="#"
                @click="
                  EmitEvent('utilization-mwb', [
                    item.call_gt_60_arr,
                    item.spoc_name,
                    item.call_gt_60,
                    'Long Calls - > 60 mins',
                  ])
                "
                >{{ item.call_gt_60 }}</a
              >
            </div>
          </vs-td>

          <vs-td :data="item.nuc_count">
            <div>
              <a
                href="#"
                @click="
                  EmitEvent('utilization-nuc', [
                    item.nuc_arr,
                    item.spoc_name,
                    item.nuc_count,
                  ])
                "
                >{{ item.nuc_count }}</a
              >
              | {{ item.nuc_duration }}
            </div>
          </vs-td>

          <vs-td :data="item.attendance">
            <div>
              {{ capitaliseFirstLetter(item.attendance.attendance_status) }}
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moment from "moment";
import EventBus from "../eventbus.js";
export default {
  props: {
    users: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      all_call_logs: [],
      // visit_engagments: [],
      // training: [],
      attendance: [],
      masterMerge: [],
    };
  },
  mounted() {
    this.all_call_logs = this.users.all_call_logs;
    this.attendance = this.users.attendance;
    console.log("this.users", this.users);
    // console.log("this.attendance", this.attendance);
    this.sortCallLogsByDay();
  },
  methods: {
    sortCallLogsByDay() {
      console.log("all_call_logs--", this.all_call_logs);
      console.log("attendance in sortCallLogsByDay", this.attendance);
      this.all_call_logs.forEach((data) => {
        data.sortDate = this.convertTimestampToDate(data.time);
      });
      this.all_call_logs.sort((a, b) =>
        a.sortDate > b.sortDate ? 1 : b.sortDate > a.sortDate ? -1 : 0
      );
      // this.visit_engagments.forEach((data) => {
      //   data.sortDate = data.created_at.split(" ")[0];
      //   data.sortDate = moment(data.sortDate, "YYYY-MM-DD").format(
      //     "DD-MMM-YYYY"
      //   );
      //   // console.log(data.sortDate);
      // });
      // this.training.forEach((data) => {
      //   data.sortDate = data.created_at.split(" ")[0];
      // });
      // this.attendance.forEach((data) => {
      //   data.sortDate = moment.unix(data.attendance_date).format("DD-MMM-YYYY");
      // });
      console.log("attendance after sorting date ", this.attendance);
      this.createDayObjects();
    },
    createDayObjects() {
      console.log("users data before master merge", this.users);
      let call_obj = [];
      // let visit_obj = [];
      // let training_obj = [];
      let attendance_obj = [];
      var unique = this.all_call_logs
        .map((name) => {
          return {
            count: 1,
            name: name.sortDate,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          let itemObj = {
            date: key,
            data: [],
          };
          call_obj.push(itemObj);
        }
      }
      call_obj.forEach((dateObj) => {
        this.all_call_logs.forEach((data) => {
          if (data.sortDate === dateObj.date) {
            dateObj.data.push(data);
          }
        });
      });
      console.log("call_obj", call_obj);
      // var unique2 = this.visit_engagments
      //   .map((name) => {
      //     return {
      //       count: 1,
      //       name: name.sortDate,
      //     };
      //   })
      //   .reduce((a, b) => {
      //     a[b.name] = (a[b.name] || 0) + b.count;
      //     return a;
      //   }, {});
      // for (var key2 in unique2) {
      //   if (unique2.hasOwnProperty(key2)) {
      //     let itemObj = {
      //       date: key2,
      //       data: [],
      //     };
      //     visit_obj.push(itemObj);
      //   }
      // }
      // visit_obj.forEach((dateObj) => {
      //   this.visit_engagments.forEach((data) => {
      //     if (data.sortDate === dateObj.date) {
      //       dateObj.data.push(data);
      //     }
      //   });
      // });
      // var unique3 = this.training
      //   .map((name) => {
      //     return {
      //       count: 1,
      //       name: name.sortDate,
      //     };
      //   })
      //   .reduce((a, b) => {
      //     a[b.name] = (a[b.name] || 0) + b.count;
      //     return a;
      //   }, {});
      // for (var key3 in unique3) {
      //   if (unique3.hasOwnProperty(key3)) {
      //     let itemObj = {
      //       date: key3,
      //       data: [],
      //     };
      //     training_obj.push(itemObj);
      //   }
      // }
      // training_obj.forEach((dateObj) => {
      //   this.training.forEach((data) => {
      //     if (data.sortDate === dateObj.date) {
      //       dateObj.data.push(data);
      //     }
      //   });
      // });

      var unique4 = this.attendance
        .map((name) => {
          return {
            count: 1,
            name: moment.unix(name.attendance_date).format("DD-MMM-YYYY"),
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key4 in unique4) {
        if (unique4.hasOwnProperty(key4)) {
          let itemObj = {
            date: key4,
            attendance_status: {},
          };
          attendance_obj.push(itemObj);
        }
      }
      console.log("attendance_obj", attendance_obj);
      attendance_obj.forEach((dateObj) => {
        this.attendance.forEach((data) => {
          let attendance_date = moment
            .unix(data.attendance_date)
            .format("DD-MMM-YYYY");
          if (attendance_date === dateObj.date) {
            dateObj.attendance_status = data.status;
          }
        });
      });
      console.log("attendance_obj 2", attendance_obj);
      // this.mergeAllSections(call_obj, visit_obj, training_obj);
      this.mergeAllSections(call_obj, attendance_obj);
      // this.calculateUtilization(obj);
    },
    mergeAllSections(call_obj, attendance_obj) {
      call_obj.forEach((call) => {
        let obj = {
          date: call.date,
        };
        this.masterMerge.push(obj);
      });
      attendance_obj.forEach((attendance) => {
        let obj = {
          date: attendance.date,
        };
        if (this.findIndexinMaster(obj) === -1) {
          this.masterMerge.push(obj);
        }
      });
      // visit_obj.forEach((visit) => {
      //   let obj = {
      //     date: visit.date,
      //   };
      //   if (this.findIndexinMaster(obj) === -1) {
      //     this.masterMerge.push(obj);
      //   }
      // });
      // training_obj.forEach((training) => {
      //   let obj = {
      //     date: training.date,
      //   };
      //   if (this.findIndexinMaster(obj) === -1) {
      //     this.masterMerge.push(obj);
      //   }
      // });
      this.masterMerge.forEach((master) => {
        master.all_call_logs = [];
        master.attendance = {};
        // master.visit_engagements = [];
        // master.training = [];
      });
      call_obj.forEach((call) => {
        this.insertRespectiveFields(call, "call");
      });
      // visit_obj.forEach((visit) => {
      //   this.insertRespectiveFields(visit, "visit");
      // });
      // training_obj.forEach((train) => {
      //   this.insertRespectiveFields(train, "training");
      // });
      console.log("masterMerge attendance_obj", attendance_obj);
      attendance_obj.forEach((att) => {
        this.insertRespectiveFields(att, "attendance");
      });
      console.log("masterMerge attendance", this.masterMerge);
      this.calculateUtilization(this.masterMerge);
    },
    insertRespectiveFields(obj, type) {
      this.masterMerge.forEach((master) => {
        if (master.date === obj.date) {
          if (type === "call") {
            master.all_call_logs = obj.data;
          }
          // else if (type === "visit") {
          //   master.visit_engagements = obj.data;
          // } else if (type === "training") {
          //   master.training = obj.data;
          // }
          else if (type === "attendance") {
            master.attendance = obj;
          }
        }
      });
    },
    findIndexinMaster(obj) {
      let returnindex = -1;
      this.masterMerge.forEach((master, index) => {
        if (master.date === obj.date) {
          returnindex = index;
        }
      });
      return returnindex;
    },
    convertTimestampToDate(timestamp) {
      let date = moment.unix(timestamp / 1000).format("DD-MMM-YYYY");
      return date;
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    timeConvert(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },
    calculateUtilization(all_call_logs) {
      console.log("all_call_logs", all_call_logs);
      for (var i = 0; i < all_call_logs.length; i++) {
        var call_duration_sec = 0;
        var total_calls = 0;
        var business_calls_array = [];
        var call_30_60 = 0;
        var call_gt_60 = 0;
        var call_30_60_arr = [];
        var call_gt_60_arr = [];
        var nuc_count = 0;
        var nuc_duration = 0;
        var nuc_arr = [];
        let connected_calls = 0;
        let connected_calls_arr = [];
        let incoming_call_count = 0;
        let outgoing_call_count = 0;
        var missed_call_count = 0;
        var missed_calls_arr = [];
        for (var j = 0; j < all_call_logs[i]["all_call_logs"].length; j++) {
          all_call_logs[i]["all_call_logs"][j].spoc_name =
            all_call_logs[i].spoc_name;
          if (
            all_call_logs[i]["all_call_logs"][j].contact_type == "B2C" ||
            all_call_logs[i]["all_call_logs"][j].contact_type == "untracked" ||
            all_call_logs[i]["all_call_logs"][j].contact_type == "spam"
          ) {
            business_calls_array.push(all_call_logs[i]["all_call_logs"][j]);
            call_duration_sec +=
              all_call_logs[i]["all_call_logs"][j].call_duration;
            total_calls++;
            if (all_call_logs[i]["all_call_logs"][j].directory === "INCOMING") {
              incoming_call_count++;
            } else if (
              all_call_logs[i]["all_call_logs"][j].directory === "OUTGOING"
            ) {
              outgoing_call_count++;
            }
            if (all_call_logs[i]["all_call_logs"][j].call_duration > 0) {
              connected_calls++;
              connected_calls_arr.push(all_call_logs[i]["all_call_logs"][j]);
            }

            if (
              all_call_logs[i]["all_call_logs"][j].call_duration >= 1800 &&
              all_call_logs[i]["all_call_logs"][j].call_duration < 3600
            ) {
              call_30_60++;
              call_30_60_arr.push(all_call_logs[i]["all_call_logs"][j]);
            } else if (
              all_call_logs[i]["all_call_logs"][j].call_duration >= 3600
            ) {
              call_gt_60++;
              call_gt_60_arr.push(all_call_logs[i]["all_call_logs"][j]);
            }
          } else {
            nuc_count++;
            nuc_arr.push(all_call_logs[i]["all_call_logs"][j]);
            nuc_duration =
              nuc_duration + all_call_logs[i]["all_call_logs"][j].call_duration;
          }
        }
        all_call_logs[i].all_call_logs.forEach((call) => {
          if (call.directory === "MISSED") {
            if (
              call.contact_type === "untracked" ||
              call.contact_type === "B2C"
            ) {
              missed_call_count++;
              missed_calls_arr.push(call);
              // if (call.updated === 0) {
              //   missed_call_count++;
              //   missed_calls_arr.push(call);
              // }
            }
          }
        });
        var unique_calls_array = [
          ...new Set(business_calls_array.map((item) => item.last_ten_digits)),
        ];
        all_call_logs[i].spoc_name = this.users.spoc_name;
        all_call_logs[i].call_duration_sec = call_duration_sec;

        all_call_logs[i].total_calls = total_calls;
        all_call_logs[i].incoming_call_count = incoming_call_count;
        all_call_logs[i].outgoing_call_count = outgoing_call_count;
        all_call_logs[i].missed_call_count = missed_call_count;
        all_call_logs[i].missed_calls_arr = missed_calls_arr;
        all_call_logs[i].total_unique_calls = unique_calls_array.length;
        all_call_logs[i].total_duration = this.secondsToHms(call_duration_sec);
        all_call_logs[i].connected_calls = connected_calls;
        all_call_logs[i].connected_calls_arr = connected_calls_arr;
        all_call_logs[i].call_30_60 = call_30_60;
        all_call_logs[i].call_30_60_arr = call_30_60_arr;
        all_call_logs[i].call_gt_60 = call_gt_60;
        all_call_logs[i].call_gt_60_arr = call_gt_60_arr;
        all_call_logs[i].nuc_count = nuc_count;
        all_call_logs[i].nuc_arr = nuc_arr;
        all_call_logs[i].nuc_duration = this.secondsToHms(nuc_duration);
      }
      this.all_call_logs = all_call_logs;
      console.log("masterMerge", this.masterMerge);
      this.$vs.loading.close();
    },
    secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    capitaliseFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    // giveAttendanceTitle(item) {
    //   let status = "";
    //   this.users.attendance.forEach((attendance) => {
    //     // attendance.formatted = moment(attendance.created_at).format("DD-MMM-YYYY");
    //     attendance.formatted = moment
    //       .unix(attendance.attendance_date)
    //       .format("DD-MMM-YYYY");
    //   });
    //   this.users.attendance.forEach((attendance) => {
    //     if (attendance.formatted === item.date) {
    //       status = attendance.status;
    //     }
    //   });
    //   return this.capitaliseFirstLetter(status);
    // },
  },
};
</script>

<style></style>