var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('vs-table',{attrs:{"max-items":"10","pagination":"","data":_vm.masterMerge},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(item,index){return _c('vs-tr',{key:index},[_c('vs-td',{attrs:{"data":item.spoc_name}},[_c('div',[_vm._v(_vm._s(item.date))])]),_c('vs-td',{attrs:{"data":item.total_calls}},[_c('div',[_vm._v("\n            "+_vm._s(item.total_calls)+" | "+_vm._s(item.total_unique_calls)+" |\n            "+_vm._s(item.total_duration)+" | "+_vm._s(item.incoming_call_count)+" |\n            "+_vm._s(item.outgoing_call_count)+" | "+_vm._s(item.missed_call_count)+"\n          ")])]),_c('vs-td',{attrs:{"data":item.connected_calls}},[_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-mwb', [
                  item.connected_calls_arr,
                  item.spoc_name,
                  item.connected_calls,
                  'Connected Calls' ])}}},[_vm._v(_vm._s(item.connected_calls))])])]),_c('vs-td',{attrs:{"data":item.call_30_60}},[_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-mwb', [
                  item.call_30_60_arr,
                  item.spoc_name,
                  item.call_30_60,
                  'Long Calls - 30-60 mins' ])}}},[_vm._v(_vm._s(item.call_30_60))]),_vm._v("\n            |\n            "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-mwb', [
                  item.call_gt_60_arr,
                  item.spoc_name,
                  item.call_gt_60,
                  'Long Calls - > 60 mins' ])}}},[_vm._v(_vm._s(item.call_gt_60))])])]),_c('vs-td',{attrs:{"data":item.nuc_count}},[_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-nuc', [
                  item.nuc_arr,
                  item.spoc_name,
                  item.nuc_count ])}}},[_vm._v(_vm._s(item.nuc_count))]),_vm._v("\n            | "+_vm._s(item.nuc_duration)+"\n          ")])]),_c('vs-td',{attrs:{"data":item.attendance}},[_c('div',[_vm._v("\n            "+_vm._s(_vm.capitaliseFirstLetter(item.attendance.attendance_status))+"\n          ")])])],1)})}}])},[_c('template',{staticClass:"exc",slot:"thead"},[_c('vs-th',[_vm._v("Date")]),_c('vs-th',[_vm._v("Total Calls|Total Unique Calls|Duration|IN|OUT|MIS")]),_c('vs-th',[_vm._v("Connected Calls")]),_c('vs-th',[_vm._v("Long Duration Calls 30-60 Mins | > 60 Mins")]),_c('vs-th',[_vm._v("Total NUC | Duration")]),_c('vs-th',[_vm._v("Attendance")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }