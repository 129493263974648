<template>
  <div class="vs-row">
    <template lang="html">
      <div>
        <vx-card actionable class="cardx" title="HR Utilization Report">
          <vs-row style="margin-bottom: 1%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="3"
            >
              <div id="datepicker">
                <date-range-picker
                  ref="picker"
                  :opens="'right'"
                  :locale-data="{ firstDay: 1, format: 'DD-MMM-YYYY' }"
                  :minDate="null"
                  :maxDate="null"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="true"
                  :showWeekNumbers="false"
                  :showDropdowns="false"
                  :autoApply="false"
                  v-model="today_date"
                  style="width: 240px"
                ></date-range-picker>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="3"
            >
              <v-select
                placeholder="Select Team"
                style="z-index: 1000;"
                class="w-full"
                v-model="selected_sub_team"
                :options="teams"
                label="name"
              ></v-select>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <vs-button color="dark" type="filled" @click="getCMData()"
                >GO</vs-button
              >
            </vs-col>
          </vs-row>
          <vs-table :data="utilization_data" v-if="loaded">
            <template slot="thead" class="exc">
              <vs-th>Spoc</vs-th>
             <vs-th>Reporting To</vs-th>
              <vs-th
                >Total Calls|Total Unique Calls|Duration|
                IN|OUT|MIS</vs-th
              >
              <vs-th>Connected Calls</vs-th>
             

              <vs-th>Long Duration Calls 30-60 Mins | > 60 Mins</vs-th>
              <vs-th>Total NUC | Duration</vs-th>
            
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="item.spoc_name" v-for="item in data">
                <vs-td :data="item.spoc_name">
                  <div>
                    {{ item.spoc_name }} {{ item.user_tag ? "-" : "" }}
                    {{
                      item.user_tag ? item.user_tag : "" ? item.user_tag : null
                    }}
                  </div>
                </vs-td>

                <vs-td :data="item.reporting_to">
                  <div>
                    {{ item.reporting_to }}
                  </div>
                </vs-td>

            
                <vs-td :data="item.total_calls">
                  <div>
                    {{ item.total_calls }} | {{ item.total_unique_calls }} |
                    {{ item.total_duration }} | {{ item.incoming_call_count }} |
                    {{ item.outgoing_call_count }} | {{ item.missed_call_count }} 
                  </div>
                </vs-td>
                
                <vs-td :data="item.connected_calls">
                  <div>
                    <a
                      href="#"
                      @click="
                        EmitEvent('utilization-mwb', [
                          item.connected_calls_arr,
                          item.spoc_name,
                          item.connected_calls,
                          'Connected Calls'
                        ])
                      "
                      >{{ item.connected_calls }}</a
                    >
                  </div>
                </vs-td>
              

                <vs-td :data="item.call_30_60">
                  <div>
                    <a href="#" @click="
                        EmitEvent('utilization-mwb', [
                          item.call_30_60_arr,
                          item.spoc_name,
                          item.call_30_60,
                          'Long Calls - 30-60 mins'
                        ])
                      ">{{
                      item.call_30_60
                    }}</a>
                    |
                    <a href="#" @click="
                        EmitEvent('utilization-mwb', [
                          item.call_gt_60_arr,
                          item.spoc_name,
                          item.call_gt_60,
                          'Long Calls - > 60 mins'
                        ])
                      ">{{
                      item.call_gt_60
                    }}</a>
                  </div>
                </vs-td>
                
                <vs-td :data="item.nuc_count">
                  <div>
                    <a
                      href="#"
                      @click="
                        EmitEvent('utilization-nuc', [
                          item.nuc_arr,
                          item.spoc_name,
                          item.nuc_count,
                        ])
                      "
                      >{{ item.nuc_count }}</a
                    >
                    | {{ item.nuc_duration }}
                  </div>
                </vs-td>
              
                
                <template class="expand-user" slot="expand">
                  <vs-row>
                    <vs-col
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                      vs-w="12"
                    >
                      <calllog :users="item"></calllog>
                    </vs-col>
                  </vs-row>
                </template>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </template>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import calllog from "../components/pagesComponents/HrCallLogComponent.vue";
import axios from "axios";
import constants from "../../constants.json";
import EventBus from "../components/eventbus.js";
import vSelect from "vue-select";
export default {
  data() {
    return {
      itsCM4: false,
      loaded: false,
      today_date: {
        startDate: new Date(),
        endDate: new Date(),
      },
      teams: ["Recruitment", "Operations"],
      selected_sub_team: "Recruitment",
      cities: [],
      all_visit_engagments: [],
      utilization_data: [],
      spocs: [],
      logged_in_user_id: "",
    };
  },
  components: {
    calllog,
    DateRangePicker,
    "v-select": vSelect,
  },
  async mounted() {
    this.getHrSpoc();
  },
  methods: {
    getCMData() {
      if (this.getRole("cm4") || this.getRole("cmca")) {
        this.reportGenerateAPI();
      } else if (!this.getRole("cm4") || !this.getRole("cmca")) {
        this.reportGenerateAPI();
      }
    },
    getRole(providedRole) {
      if (localStorage.getItem("role") === providedRole) {
        return true;
      } else {
        return false;
      }
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    timetoTimestamp(selected_date) {
      var timestamp = new Date(selected_date).setHours(0, 0, 0, 0) / 1000;
      return timestamp;
    },
    showDangerNotification(response) {
      this.$vs.notify({
        title: "Danger",
        text: response.data.message,
        color: "danger",
      });
    },
    showWarningNotification(response) {
      this.$vs.notify({
        title: "Warning",
        text: response.data.message + " in line " + response.data.line,
        color: "warning",
      });
    },
    showErrorNotification(error) {
      this.$vs.notify({
        title: "Danger",
        text: error.response,
        color: "danger",
      });
    },
    getHrSpoc() {
      let url = `${constants.MILES_CM_BACK}getHRTeamForUtilisation`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("Hr Spoc", response);
          this.spocs = response.data;
          // this.getSpocsByTeam(this.spocs);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async reportGenerateAPI() {
      console.log("hr spoc", this.spocs);
        this.$vs.loading();
        this.loaded = false;

      let spocjoin = [];
      if (this.getRole("cm4") || this.getRole("hr")) {
        this.spocs.forEach((spoc) => {
          if(spoc.sub_team == this.selected_sub_team){
            spocjoin.push(spoc.id);
          }
        });
      } 
      console.log("spoc join", spocjoin);

      let spocsCollection = [];
      for (let i = 0; i < spocjoin.length; i++) {
        let params = {
          user_id: spocjoin[i],
          from_date: this.timetoTimestamp(this.today_date.startDate),
          to_date: this.timetoTimestamp(this.today_date.endDate),
        };
        let response = await this.getUtilization(params);
        spocsCollection.push(response.data.data);
        console.log(response);
      }
      console.log("spoc collection", spocsCollection);
      this.calculateUtilization(spocsCollection);
      console.log("utilization_data",this.utilization_data);
      this.$vs.loading.close();
    },
    getUtilization(params) {
      let url = `${constants.SERVER_API}getHRCallLogsForUtilisation`;
      const promise = new Promise((resolve, reject) => {
        axios
          .get(url, {
            params: params,
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            this.handleError(error);
            reject();
          });
      });
      return promise;
    },
    calculateUtilization(utilization_data) {
      for (var i = 0; i < utilization_data.length; i++) {
        var call_duration_sec = 0;
        var total_calls = 0;
        var business_calls_array = [];
        var incoming_call_count = 0;
        var outgoing_call_count = 0;
        var missed_call_count = 0;
        var call_30_60 = 0;
        var call_30_60_arr = [];
        var call_gt_60_arr = [];
        var call_gt_60 = 0;
        var nuc_count = 0;
        var nuc_duration = 0;
        var missed_calls_arr = [];
        var nuc_arr = [];
        let connected_calls = 0;
        let connected_calls_arr = [];
      
        for (var j = 0; j < utilization_data[i]["all_call_logs"].length; j++) {
          utilization_data[i]["all_call_logs"][j].spoc_name =
            utilization_data[i].spoc_name;
          if (
            utilization_data[i]["all_call_logs"][j].contact_type == "B2C" || utilization_data[i]["all_call_logs"][j].contact_type == "untracked" || utilization_data[i]["all_call_logs"][j].contact_type == "spam" 
          ) {
          
              business_calls_array.push(
                utilization_data[i]["all_call_logs"][j]
              );
              call_duration_sec +=
                utilization_data[i]["all_call_logs"][j].call_duration;
              total_calls++;
              if (
                utilization_data[i]["all_call_logs"][j].directory === "INCOMING"
              ) {
                incoming_call_count++;
              } else if (
                utilization_data[i]["all_call_logs"][j].directory === "OUTGOING"
              ) {
                outgoing_call_count++;
              }
              if (utilization_data[i]["all_call_logs"][j].call_duration > 0) {
                connected_calls++;
                connected_calls_arr.push(
                  utilization_data[i]["all_call_logs"][j]
                );
              }
             
              if (
                utilization_data[i]["all_call_logs"][j].call_duration >= 1800 &&
                utilization_data[i]["all_call_logs"][j].call_duration < 3600
              ) {
                call_30_60++;
                call_30_60_arr.push(utilization_data[i]["all_call_logs"][j])
              } else if (
                utilization_data[i]["all_call_logs"][j].call_duration >= 3600
              ) {
                call_gt_60++;
                call_gt_60_arr.push(utilization_data[i]["all_call_logs"][j])
              }
          } else {
            nuc_count++;
            nuc_arr.push(utilization_data[i]["all_call_logs"][j]);
            nuc_duration =
              nuc_duration +
              utilization_data[i]["all_call_logs"][j].call_duration;
          }
        }
        utilization_data[i].all_call_logs.forEach((call) => {
          if (call.directory === "MISSED") {
            if (
              call.contact_type === "untracked" ||
              call.contact_type === "B2C"
            ) {
              missed_call_count++;
                missed_calls_arr.push(call);
              // if (call.updated === 0) {
              //   missed_call_count++;
              //   missed_calls_arr.push(call);
              // }
            }
          }
        });
        var unique_calls_array = [
          ...new Set(business_calls_array.map((item) => item.last_ten_digits)),
        ];
        var call_duration_mins = Math.round(call_duration_sec / 60);
        var call_utilization = Math.round((call_duration_mins * 100) / 180);
        utilization_data[i].incoming_call_count = incoming_call_count;
        utilization_data[i].outgoing_call_count = outgoing_call_count;
        utilization_data[i].call_duration_sec = call_duration_sec;
        utilization_data[i].call_utilization = call_utilization + "%";
        utilization_data[i].total_calls = total_calls;
        utilization_data[i].total_unique_calls = unique_calls_array.length;
        utilization_data[i].total_duration = this.secondsToHms(call_duration_sec);
        utilization_data[i].connected_calls = connected_calls;
        utilization_data[i].connected_calls_arr = connected_calls_arr;
        utilization_data[i].missed_call_count = missed_call_count;
        utilization_data[i].missed_calls_arr = missed_calls_arr;
        utilization_data[i].call_30_60 = call_30_60;
        utilization_data[i].call_30_60_arr = call_30_60_arr
        utilization_data[i].call_gt_60 = call_gt_60;
        utilization_data[i].call_gt_60_arr = call_gt_60_arr
        utilization_data[i].nuc_count = nuc_count;
        utilization_data[i].nuc_arr = nuc_arr;
        utilization_data[i].nuc_duration = this.secondsToHms(nuc_duration);
      }
      this.utilization_data = utilization_data;
      this.loaded = true;
      this.$vs.loading.close();
    },
    secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },
  },
};
</script>
<style>
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
}
#datepicker {
  z-index: 1000;
}
.vs-table--tbody-table tr {
  font-family: sans-serif;
  font-size: smaller;
}
</style>