var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vs-row"},[[_c('div',[_c('vx-card',{staticClass:"cardx",attrs:{"actionable":"","title":"HR Utilization Report"}},[_c('vs-row',{staticStyle:{"margin-bottom":"1%"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"3"}},[_c('div',{attrs:{"id":"datepicker"}},[_c('date-range-picker',{ref:"picker",staticStyle:{"width":"240px"},attrs:{"opens":'right',"locale-data":{ firstDay: 1, format: 'DD-MMM-YYYY' },"minDate":null,"maxDate":null,"singleDatePicker":false,"timePicker":false,"timePicker24Hour":true,"showWeekNumbers":false,"showDropdowns":false,"autoApply":false},model:{value:(_vm.today_date),callback:function ($$v) {_vm.today_date=$$v},expression:"today_date"}})],1)]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"3"}},[_c('v-select',{staticClass:"w-full",staticStyle:{"z-index":"1000"},attrs:{"placeholder":"Select Team","options":_vm.teams,"label":"name"},model:{value:(_vm.selected_sub_team),callback:function ($$v) {_vm.selected_sub_team=$$v},expression:"selected_sub_team"}})],1),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"2"}},[_c('vs-button',{attrs:{"color":"dark","type":"filled"},on:{"click":function($event){return _vm.getCMData()}}},[_vm._v("GO")])],1)],1),(_vm.loaded)?_c('vs-table',{attrs:{"data":_vm.utilization_data},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(item){return _c('vs-tr',{key:item.spoc_name},[_c('vs-td',{attrs:{"data":item.spoc_name}},[_c('div',[_vm._v("\n                  "+_vm._s(item.spoc_name)+" "+_vm._s(item.user_tag ? "-" : "")+"\n                  "+_vm._s(item.user_tag ? item.user_tag : "" ? item.user_tag : null)+"\n                ")])]),_c('vs-td',{attrs:{"data":item.reporting_to}},[_c('div',[_vm._v("\n                  "+_vm._s(item.reporting_to)+"\n                ")])]),_c('vs-td',{attrs:{"data":item.total_calls}},[_c('div',[_vm._v("\n                  "+_vm._s(item.total_calls)+" | "+_vm._s(item.total_unique_calls)+" |\n                  "+_vm._s(item.total_duration)+" | "+_vm._s(item.incoming_call_count)+" |\n                  "+_vm._s(item.outgoing_call_count)+" | "+_vm._s(item.missed_call_count)+" \n                ")])]),_c('vs-td',{attrs:{"data":item.connected_calls}},[_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-mwb', [
                        item.connected_calls_arr,
                        item.spoc_name,
                        item.connected_calls,
                        'Connected Calls'
                      ])}}},[_vm._v(_vm._s(item.connected_calls))])])]),_c('vs-td',{attrs:{"data":item.call_30_60}},[_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-mwb', [
                        item.call_30_60_arr,
                        item.spoc_name,
                        item.call_30_60,
                        'Long Calls - 30-60 mins'
                      ])}}},[_vm._v(_vm._s(item.call_30_60))]),_vm._v("\n                  |\n                  "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-mwb', [
                        item.call_gt_60_arr,
                        item.spoc_name,
                        item.call_gt_60,
                        'Long Calls - > 60 mins'
                      ])}}},[_vm._v(_vm._s(item.call_gt_60))])])]),_c('vs-td',{attrs:{"data":item.nuc_count}},[_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-nuc', [
                        item.nuc_arr,
                        item.spoc_name,
                        item.nuc_count ])}}},[_vm._v(_vm._s(item.nuc_count))]),_vm._v("\n                  | "+_vm._s(item.nuc_duration)+"\n                ")])]),_c('template',{staticClass:"expand-user",slot:"expand"},[_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"12"}},[_c('calllog',{attrs:{"users":item}})],1)],1)],1)],2)})}}],null,false,4122900638)},[_c('template',{staticClass:"exc",slot:"thead"},[_c('vs-th',[_vm._v("Spoc")]),_c('vs-th',[_vm._v("Reporting To")]),_c('vs-th',[_vm._v("Total Calls|Total Unique Calls|Duration|\n              IN|OUT|MIS")]),_c('vs-th',[_vm._v("Connected Calls")]),_c('vs-th',[_vm._v("Long Duration Calls 30-60 Mins | > 60 Mins")]),_c('vs-th',[_vm._v("Total NUC | Duration")])],1)],2):_vm._e()],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }